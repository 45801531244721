
.header-search-container{
   display: flex;
   flex-direction: row-reverse;
   width: 100%;
}
input:focus {
   outline: none;
   box-shadow: none;
   /* box-shadow: -5px 0 10px 2px  #00ffff13, 0 0 0 1px  #0099ff10; */
 }
 select:focus {
   outline: none;
   box-shadow: none;
   /* box-shadow: 0 0 10px 2px  #00ffff13, 0 0 0 1px  #80ff0010; */
 }

 input::selection {
   background-color: rgba(26, 120, 228, 0.336); /* Ustaw kolor tła karetki na czerwony */
 }
.header-search-input{
   
   -webkit-user-modify: read-only;
   width: 100%;
   max-width: 300px;
   height: fit-content;
   margin-block: auto;
   margin-inline: 10px;
   font-size: 18px;
   padding-block: 5px;
   padding-inline: 10px;
   background: none;
   caret-color: rgb(225, 0, 255);
   /* Usunięcie obramowania */
   /* border: 1px solid gray; */
   color: var(--maintext-color);
   /* Dodanie cienia pudełka z dwoma kolorami neonowymi */
   /* box-shadow: 0 0 10px 2px  var(--shadow-color-one), 0 0 0 1px  var(--shadow-color-two); */
   border-radius: 2px;
   border: 1px solid rgba(128, 128, 128, 0.247);
   transition: all 0.4s ease;
   /* Dodanie przezroczystego tekstu zastępczego */
   &::placeholder {
      color: rgba(228, 140, 26, 0.918);
    }
   
   /* Dodanie białego koloru karetki */
   &::-webkit-input-placeholder {
      color: rgba(136, 136, 136, 0.336);
    }
   
  
}
.header-search-input::focus {
   /* Zresetuj style focusu */
   outline: none;
   box-shadow: none;
   border: 0px solid transparent;
 }

.header-search-buttons-container{
   display: flex;
   flex-direction: row;
   width: fit-content;
}
.header-search-button{
   margin-inline: 3px;
   margin-block: auto;
   background: transparent;
   border: 0px solid #b6f4ff6b;
   box-shadow: 0px 0px 3px rgba(94, 143, 199, 0.925);
   border-radius: 4px;
   cursor: pointer;
   width: fit-content;
   height: fit-content;
   padding: 0px;
}
.search-icon{
   margin: 2px;
   width: 26px;
   height: 26px;
   fill: var(--secondary-fill-color);
}
.func-container{
   width: fit-content;
   height: fit-content;
   margin-block: auto;
}
.func-button{
   margin-inline: 3px;
   margin-block: auto;
   background: transparent;
   border: 0px solid #b6f4ff6b;
   box-shadow: 0px 0px 3px rgba(94, 143, 199, 0.925);
   border-radius: 4px;
   cursor: pointer;
   width: fit-content;
   height: fit-content;
   padding: 0px;
   transition: background 0.3s ease;
}
.func-button:hover{
   background:rgba(86, 159, 243, 0.366);
}
.folder-container{
   transition: all 0.4s ease;
}
.peaces{
   display: flex;
   flex-wrap: wrap;
}
.infopeac{
   margin-inline: 5px;
   display: flex;
   flex-direction: row;
   margin-block: auto;
   border: 1px solid rgba(54, 152, 121, 0.89);
   padding: 3px;
   border-radius: 3px;
}
.infopeac p{
   margin-inline: 2px;
   font-family: system-ui;

}
@media screen and (max-width: 900px) {
   .header-txt{
      display: none;
   }
   .search-icon{
      margin: 2px;
      width: 30px;
      height: 30px;
      fill: var(--secondary-fill-color);
   }
}