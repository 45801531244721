
.pipe-container{
   display: flex;
   flex-direction: row;
}

.pipe-column{
   width: 100%;
   max-width: 220px;
   margin-inline: 1px;
   margin-top: 10px;
   min-height: 90vh;
}
.pipe-column-header{
   padding-block: 10px;
   margin-bottom: 10px;
   text-align: center;
   min-width: 150px;
   border-inline: 0.5px solid rgb(70, 126, 151);
   /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.458); */
}

.pipe-card{
   cursor: pointer;
   border: 0px solid gray;
   box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.644);
   padding-block: 4px;
   border-top: 20px solid var(--theme-bgGreef);
   padding-inline: 3px;
   margin-block: 2px;
   border-radius: 1px;
   backdrop-filter: blur(2px);
   position: relative;
   transition: all 0.4s ease;
}
.pipe-menu{
  position: absolute;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
   margin-block: auto;
   margin-inline: auto;
   width: 300px;
   height: fit-content;
   transition: all 0.4s ease;
}
.menu-btn-goto{
   height: 200px;
   width: 200px;
   cursor: pointer;
   position: relative;
   margin-inline: auto;
}
.menu-btn-goto::after{
   content: '';
   position: absolute;
   top: 0;
   left:0;
   right: 0;
   bottom: 0;
   margin-inline: auto;
   margin-block: auto;
   height: 70px;
   width: 70px;
   background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 -960 960 960" fill="rgb(42, 158, 158)" xmlns="http://www.w3.org/2000/svg"><path d="M480-400q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400ZM320-240h320v-23q0-24-13-44t-36-30q-26-11-53.5-17t-57.5-6q-30 0-57.5 6T369-337q-23 10-36 30t-13 44v23ZM720-80H240q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80Zm0-80v-446L526-800H240v640h480Zm-480 0v-640 640Z"/></svg>');
   background-repeat: no-repeat;
   background-position: center;
   
}
.menu-pipe-selector{
   height: fit-content;
   width: fit-content;
   user-select: none;
   width: 100%;
   display: flex;
   flex-direction: column;
}
.menu-calendar-selector{
   height: fit-content;
   width: fit-content;
   user-select: none;
   width: 100%;
   justify-content: space-evenly;
   display: flex;
   flex-direction: column;
}

.card-pipe-selector{
   height: fit-content;
   width: fit-content;
   position: absolute;
   right: 0px;
   user-select: none;
   display: flex;
   flex-direction: column;
   
}
.card-selector-stage{
   position: relative;
   padding: 10px;
   background: rgb(136, 136, 136);
   backdrop-filter: blur(5px);
   margin-block: 2px;
   border-radius: 3px;
   transition: all 0.3s ease;
   z-index: 100;
}
.card-selector-stage:hover{
   padding: 10px;
   scale: 1.1;
}
.pipe-card-line{
   height: 4px;
   width: 95%;
   margin-inline: auto;
   background: rgba(160, 160, 160, 0.509);
   border-radius: 1px;
   border: 1px solid rgba(129, 129, 129, 0.167);
   filter: blur(5px);
}
.pipe-card-line-inner{
   height: 4px;
   background: rgb(29, 168, 87);
}
.card-btn-goto{
   height: 100%;
   width: 50px;
   backdrop-filter: blur(2px);
   position: absolute;
   bottom: 0;
   left: 0;
   cursor: pointer;
}
.card-btn-goto::after{
   content: '';
   position: absolute;
   top: 0;
   left:0;
   right: 0;
   bottom: 0;
   margin-inline: auto;
   margin-block: auto;
   height: 30px;
   width: 30px;
   background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 -960 960 960" fill="rgb(42, 158, 158)" xmlns="http://www.w3.org/2000/svg"><path d="M480-400q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400ZM320-240h320v-23q0-24-13-44t-36-30q-26-11-53.5-17t-57.5-6q-30 0-57.5 6T369-337q-23 10-36 30t-13 44v23ZM720-80H240q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80Zm0-80v-446L526-800H240v640h480Zm-480 0v-640 640Z"/></svg>');
   background-repeat: no-repeat;
   background-position: center;
   
}
.card-btn-stage{
   cursor: pointer;
   height: 100%;
   width: 50px;
   backdrop-filter: blur(2px);
   position: absolute;
   bottom: 0;
   right: 0;
}
.card-btn-stage::after{
   content: '';
   position: absolute;
   top: 0;
   left:0;
   right: 0;
   bottom: 0;
   margin-inline: auto;
   margin-block: auto;
   height: 30px;
   width: 30px;
   background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 -960 960 960" fill="rgb(42, 158, 158)" xmlns="http://www.w3.org/2000/svg"><path d="M80-240v-480h80v480H80Zm560 0-57-56 144-144H240v-80h487L584-664l56-56 240 240-240 240Z"/></svg>');
   background-repeat: no-repeat;
   background-position: center;
   
}


.pipe-card-title{
   font-size: 14px;
   font-weight: 500;
   text-align: center;
   border-bottom: 1px solid rgba(128, 128, 128, 0.308);
  margin-block: 2px;
}

.pipe-card-price{
   font-size: 13px;
   margin-block: 2px;
   text-align: right;
   color: gray;
   font-weight: thin;
}
.pipe-card-prod{
   margin-block: 2px;
   font-size: 13px;
   margin-inline: 5px;
}
.horizontal-scroll {
   display: flex;
   overflow-x: auto;
   cursor: grab;
   user-select: none; /* zapobiega zaznaczaniu tekstu podczas przewijania */
 }
 
 .horizontal-scroll:active {
   cursor: grabbing;
 }
 
 .horizontal-scroll > * {
   flex: 0 0 auto; /* zapobiega łamaniu się elementów w nowej linii */
   
 }