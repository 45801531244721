.flex-wrapper{
   display: flex;
   flex-wrap: wrap;
   justify-content: first baseline;
}

.flex-elem{
   width: fit-content;
   max-width: 100px;
 
   padding: 10px;
}
.grid-card{
   border: 1px solid rgb(112, 143, 242);
   cursor: pointer;
   border-radius: 5px;
   box-shadow: 0 0 10px rgba(128, 128, 128, 0.356);
   transition: all 0.3s ease;
   margin: 10px;
}
.grid-txt{
   font-size: 15px;
   text-align: center;
   border-block: 4px solid rgba(97, 196, 223, 0.478);
}
.grid-card:hover{

   scale: 1.04;
   box-shadow: 0 0 20px rgba(128, 128, 128, 0.356);
}

.flex-img{
   width: available;
   max-width: 100%;
   object-fit: cover;
   height: auto;
   min-height: 70px;
}