.table{
   border: 0px solid transparent ;
   width: 100%;
   transition: all 0.6s ease;
   border-collapse: separate;
   border-spacing: 2px;
}
.table-header{
   transition: all 0.6s ease;
   border-bottom: 1px solid rgba(122, 122, 122, 0) ;
   /* background: rgba(63, 110, 124, 0.182); */
}
.table-header-bot-con{
   
   border-bottom: 1px solid rgba(122, 122, 122, 0.479) ;
   /* background: linear-gradient(0deg, rgba(124, 153, 160, 0.403) 0%, rgba(137, 255, 133, 0.134) 100%); */
}
.table-header-bot-ans{
   
   border-bottom: 3px solid rgba(0, 0, 0, 0.996) ;
   /* background:rgba(1, 19, 24, 0.2); */
}
.table-body{
   border: 0px solid transparent;
   
}
.table-h-tr{

}
.table-h-th{
   font-weight: 300;
   text-align: left;
   padding-inline: 0.3%;
   padding-block: 4px;
   white-space:nowrap;
   border-radius: 1px;
   border-block:1px solid rgb(182, 182, 182);
   /* box-shadow: 0 0 5px rgba(5, 5, 5, 0.384); */
}
.table-h-tr-bot-con{
   padding-block: 4px;
   border-block:1px solid rgba(182, 182, 182, 0.389);
}
.table-h-th-bot-con{
   font-weight: 300;
   text-align: left;
   padding-inline: 0.3%;
   /* white-space:nowrap; */
   transition: all 0.6s ease;
   animation: apear-table-row 0.4s ease forwards;
}
.table-h-th-bot-ans{
   font-weight: 300;
   text-align: left;
   padding-inline: 0.3%;
   white-space:nowrap;
   transition: all 0.6s ease;
   animation: apear-table-row 0.4s ease forwards;
}
@keyframes apear-table-row {
   0%{
      opacity: 0;
   }
   100%{
      opacity: 1;
   }
}
.table-b-tr{
   border-bottom: 0px solid rgba(143, 143, 143, 0.096);
   
}
.table-b-tr-bot-con{
   background: linear-gradient(90deg,rgb(57, 201, 126) 0%, rgba(108, 154, 220, 0.852) 100%)  ;
   border-bottom: 0px solid rgba(170, 170, 170, 0.853);
   box-shadow: -5px 0px  10px rgba(21, 150, 60, 0.479) ;
   border-radius: 15px;
   opacity: 0;
   transition: all 0.4s ease;
   animation: apear-table-row 1s 0.5s  ease forwards;
}
.table-b-tr-bot-ans{
   
   border-bottom: 0px solid rgba(170, 170, 170, 0.853);
   box-shadow: -5px 0px  10px rgba(21, 126, 150, 0.151) ;
   border-radius: 0px;
   opacity: 0;
   transition: all 0.4s ease;
   animation: apear-table-row 0.6s   ease forwards;
}
.table-b-tr-bot-ans td:first-child{
   background: linear-gradient(90deg, rgba(12, 32, 59, 0.452) 0%,  rgba(19, 87, 93, 0.485) 100%);
   padding-block: 10px;
}
.table-b-tr-bot-mess{
   
   border-bottom: 0px solid rgba(170, 170, 170, 0.853);
   box-shadow: -5px 0px  10px rgba(21, 126, 150, 0.137) ;
   border-radius: 0px;
   opacity: 0;
   transition: all 0.4s ease;
   animation: apear-table-row 0.6s   ease forwards;
}
.table-b-tr-bot-mess td:nth-child(2){
   
   background: rgba(2, 2, 2, 0.438);
   border: 2px solid gray;
   border-radius: 0 10px 10px 10px;
   padding-block: 10px;
   font-style: italic;
}
.table-b-tr-bot-mess td:nth-child(1){
   font-weight: 100;
   background: rgba(1, 16, 29, 0.274);
   border: 0px solid gray;
   border-radius: 10px 0px 0px 10px;
   padding-block: 10px;
}
.table-b-tr-bot-lessons td:nth-child(2){
   
   
   border-radius: 0 0px 0px 0px;
   padding-block: 10px;
   font-style: italic;
}
.table-b-tr-bot-lessons td:nth-child(1){
   font-weight: 100;
   background: rgba(73, 111, 145, 0.584);
   border-left: 1px solid rgb(51, 134, 159);
   border-radius: 4px 0px 0px 4px;
   padding-block: 10px;
}
.table-b-tr-bot-lessons td:last-child{
   font-weight: 100;
   border-right: 1px solid rgb(51, 134, 159);
   border-radius: 2px 2px 2px 2px;
   padding-block: 10px;
}
.table-buttons-container{
   white-space: pre-line;
   margin: auto;
   display: flex;
   flex-direction: row;
   height: 100%;
   width: 100%;
}
.table-b-td{
   background: var(--bg-table-color);
   /* background: rgba(24, 27, 27, 0.537); */
   padding-inline: 0.3%;
   /* box-shadow: 0 0 6px 2px rgba(14, 14, 14, 0.321); */
}
.table-b-td-bot-ans{
   background: var(--bg-table-color);
   padding-inline: 0.3%;
}
.table-b-td-bot-con{
   padding-inline: 0.3%;
   border-radius: 7px;
}
.table-b-td-bot-ans{

   padding-inline: 0.3%;
   border-radius: 0px;
}
.table-b-td-bot-con div{
   color: black;
   padding-left: 0px;
   word-break: break-all;
   transition: all 0.3s ease;
}
.table-b-td div{
   
   padding-left: 0px;
   word-break: break-all;
   transition: all 0.3s ease;
}
.table-b-td div span:hover{
   scale: 1.4;
}
.table-b-td div span{
   box-shadow: 0px 0px 3px rgba(94, 143, 199, 0);
   transition: all 0.5s ease;
}

.icon-delete{
   background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 -960 960 960" fill="rgb(220, 43, 43)" xmlns="http://www.w3.org/2000/svg"><path d="m376-300 104-104 104 104 56-56-104-104 104-104-56-56-104 104-104-104-56 56 104 104-104 104 56 56Zm-96 180q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520Zm-400 0v520-520Z" fill="rgb(250, 122, 67)"/></svg>');
   background-repeat: no-repeat;
   background-position: center;
   width: 24px;
  height: 24px;
  cursor: pointer;
  margin-block: 1px;
  margin-inline: 2px;
  border-radius: 2px;
}
.icon-edit{
   background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 -960 960 960" fill="rgb(42, 158, 158)" xmlns="http://www.w3.org/2000/svg"><path d="M160-400v-80h280v80H160Zm0-160v-80h440v80H160Zm0-160v-80h440v80H160Zm360 560v-123l221-220q9-9 20-13t22-4q12 0 23 4.5t20 13.5l37 37q8 9 12.5 20t4.5 22q0 11-4 22.5T863-380L643-160H520Zm300-263-37-37 37 37ZM580-220h38l121-122-18-19-19-18-122 121v38Zm141-141-19-18 37 37-18-19Z"/></svg>');
   background-repeat: no-repeat;
   background-position: center;
   width: 27px;
  height: 27px;
  color: rgb(42, 158, 158);
  cursor: pointer;
  margin-block: 1px;
  margin-inline: 2px;
  border-radius: 2px;
}
.icon-card{
   background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 -960 960 960" fill="rgb(42, 158, 158)" xmlns="http://www.w3.org/2000/svg"><path d="M480-400q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400ZM320-240h320v-23q0-24-13-44t-36-30q-26-11-53.5-17t-57.5-6q-30 0-57.5 6T369-337q-23 10-36 30t-13 44v23ZM720-80H240q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80Zm0-80v-446L526-800H240v640h480Zm-480 0v-640 640Z"/></svg>');
   background-repeat: no-repeat;
   background-position: center;
   width: 24px;
  height: 23px;
  color: rgb(42, 158, 158);
  cursor: pointer;
  margin-block: 1px;
  margin-inline: 2px;
  border-radius: 2px;
}
.icon-menu{
 
   background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 -960 960 960" fill="rgb(42, 158, 158)" xmlns="http://www.w3.org/2000/svg"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-480H200v480Zm280-80q-82 0-146.5-44.5T240-440q29-71 93.5-115.5T480-600q82 0 146.5 44.5T720-440q-29 71-93.5 115.5T480-280Zm0-60q56 0 102-26.5t72-73.5q-26-47-72-73.5T480-540q-56 0-102 26.5T306-440q26 47 72 73.5T480-340Zm0-100Zm0 60q25 0 42.5-17.5T540-440q0-25-17.5-42.5T480-500q-25 0-42.5 17.5T420-440q0 25 17.5 42.5T480-380Z"/></svg>');
   background-repeat: no-repeat;
   background-position: center;
   width: 24px;
  height: 23px;
  color: rgb(42, 158, 158);
  cursor: pointer;
  margin-block: 1px;
  margin-inline: 2px;
  border-radius: 2px;
}
.icon-download{
   background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" fill="rgb(42, 158, 158)" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2v9.67z"/></svg>');
   background-repeat: no-repeat;
   background-position: center;
   width: 24px;
  height: 23px;
  color: rgb(42, 158, 158);
  cursor: pointer;
  margin-block: 1px;
  margin-inline: 2px;
  border-radius: 2px;
}
.icon-copy{

   background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" fill="rgb(42, 158, 158)" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>');
   background-repeat: no-repeat;
   background-position: center;
   width: 24px;
  height: 23px;
  color: rgb(42, 158, 158);
  cursor: pointer;
  margin-block: 1px;
  margin-inline: 2px;
  border-radius: 2px;
}
.icon-copyurl{

   background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 -960 960 960" fill="rgb(42, 158, 158)" xmlns="http://www.w3.org/2000/svg"><path d="M838-65 720-183v89h-80v-226h226v80h-90l118 118-56 57ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 20-2 40t-6 40h-82q5-20 7.5-40t2.5-40q0-20-2.5-40t-7.5-40H654q3 20 4.5 40t1.5 40q0 20-1.5 40t-4.5 40h-80q3-20 4.5-40t1.5-40q0-20-1.5-40t-4.5-40H386q-3 20-4.5 40t-1.5 40q0 20 1.5 40t4.5 40h134v80H404q12 43 31 82.5t45 75.5q20 0 40-2.5t40-4.5v82q-20 2-40 4.5T480-80ZM170-400h136q-3-20-4.5-40t-1.5-40q0-20 1.5-40t4.5-40H170q-5 20-7.5 40t-2.5 40q0 20 2.5 40t7.5 40Zm34-240h118q9-37 22.5-72.5T376-782q-55 18-99 54.5T204-640Zm172 462q-18-34-31.5-69.5T322-320H204q29 51 73 87.5t99 54.5Zm28-462h152q-12-43-31-82.5T480-798q-26 36-45 75.5T404-640Zm234 0h118q-29-51-73-87.5T584-782q18 34 31.5 69.5T638-640Z"/></svg>');
   background-repeat: no-repeat;
   background-position: center;
   width: 24px;
  height: 23px;
  color: rgb(42, 158, 158);
  cursor: pointer;
  margin-block: 1px;
  margin-inline: 2px;
  border-radius: 2px;
}
.icon-copyframe{

   background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 -960 960 960" fill="rgb(42, 158, 158)" xmlns="http://www.w3.org/2000/svg"><path d="M320-240 80-480l240-240 57 57-184 184 183 183-56 56Zm320 0-57-57 184-184-183-183 56-56 240 240-240 240Z"/></svg>');
   background-repeat: no-repeat;
   background-position: center;
   width: 24px;
  height: 23px;
  color: rgb(42, 158, 158);
  cursor: pointer;
  margin-block: 1px;
  margin-inline: 2px;
  border-radius: 2px;
}
.icon-messages{

   background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" fill="rgb(167, 83, 246)" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M0 0h24v24H0V0z" fill="none"/><path d="M15 4v7H5.17l-.59.59-.58.58V4h11m1-2H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1V3c0-.55-.45-1-1-1zm5 4h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1z"/></svg>');
   background-repeat: no-repeat;
   background-position: center;
   width: 24px;
  height: 23px;
  color: rgb(167, 83, 246);
  cursor: pointer;
  margin-block: 1px;
  margin-inline: 2px;
  border-radius: 2px;
}
.icon-forward{
   background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" fill="rgb(72, 240, 57)" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M14 8.83L17.17 12 14 15.17V14H6v-4h8V8.83M12 4v4H4v8h8v4l8-8-8-8z"/></svg>');
   background-repeat: no-repeat;
   background-position: center;
   width: 24px;
  height: 23px;
  color: rgb(72, 240, 57);
  cursor: pointer;
  margin-block: 1px;
  margin-inline: 2px;
  border-radius: 2px;
}
@media screen and (max-width: 800px){
   .table-body{
      border: 0px solid transparent;
   }
   .table-h-tr{
      display: none;
   }
   
   .table-b-td div span{
      width: 35px;
      height: 35px;
      margin-inline: auto;
   }
   .table-b-tr{
      display: flex;
      flex-direction: column;
      justify-content: left;
      font-size: 0.9rem;
      background: var(--bg-element-color);
      margin-block: 6px;
      padding: 2px;
      border-radius: 3px;
      border-left: 2px solid var(--border-left-color);
      max-height: 60px;
      overflow: hidden;
      transition: all 0.6s ease;
   }
   .table-b-tr-bot-mess{
      display: flex;
      flex-direction: column;
      justify-content: left;
      font-size: 0.9rem;
      background: var(--bg-element-color);
      margin-block: 6px;
      padding: 2px;
      border-radius: 3px;
      border-left: 2px solid var(--border-left-color);
      max-height: 60px;
      overflow: hidden;
      transition: all 0.6s ease;
   }
   .table-b-tr:hover{
      max-height: fit-content;
      border-left: 3px solid rgb(81, 125, 219);
      border-right: 3px solid rgb(81, 125, 219);
      background: rgba(82, 151, 184, 0.288);
      filter: brightness(1.1) ;
   }
   .table-b-td div{
     word-break:normal;
     margin-block:3px ;
     padding: 3px;
     margin-inline: auto;
   }
   .table-b-td div:before{
      content: attr(data-label)'  ';
      margin-right: 5px;
      color: gray;
    }
   .table-b-td{
      width: available;
      border: 0px solid transparent;
   }
}
