
  :root {
    --bg-color: #1e2322;
    --bgprimary-color:linear-gradient(333deg, rgb(18 20 22) 1%, rgb(52 97 91) 100%);
    --secondary-fill-color: #ececec;
    --maintext-color: #b9b1b1;
    --secondarytext-color: #b9b1b1;


    --secondayr-btns : #07268d38;
    --border-color:hsl(203, 11%, 23%);
    --lines-color: hsla(275, 8%, 46%, 0.247);

    --border-left-color:hsl(147, 52%, 68%);
    --bg-element-color:rgba(0, 132, 255, 0.041);
    --bg-modal-color: rgba(14, 14, 14, 0.616);
    --bg-modal-color2:linear-gradient(45deg, rgba(2, 29, 27, 0.88), rgba(1, 9, 26, 0.62));
    --theme-bgGreef: rgb(21 43 31 / 23%);
    --bg-buttons-color: linear-gradient(333deg, rgb(18 20 22) 1%, rgb(52 97 91) 100%);
    --border-width:3px;
    --bg-table-color:rgb(10 20 18 / 52%);
    --bg-inputs-color:rgb(59 63 69 / 34%);

    

    --shadow-color-one:#00ffff10;
    --shadow-color-two:#50e8fc4d;
    
    --lighttext-color: #63dac8;
    --meintext-size:min(max(25px,3.556vw),17px);    

    --nav-text-width: fit-content;
    --width-nav:300px;
  }
  @font-face {
    font-family: 'LibreFranklin';
    src: url('./fonts/Libre_Franklin/LibreFranklin-VariableFont_wght.ttf') format('truetype');
    /* Dodaj inne formaty czcionek, jeśli są dostępne */
    font-weight: normal; /* lub inny wariant */
    font-style: normal;
    color: rgb(95, 154, 196);  /* lub inny wariant */
  }
  
  

  input:-webkit-autofill {
    background-color: #6847472a; /* Ustaw dowolny kolor tła */
    /* Dodatkowe stylizacje */
    border: 1px solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    /* Inne właściwości stylu według potrzeb */
}
/* Dla wskaźnika tekstu (karetki) wewnątrz pola input */
/* input::selection {
  background-color: rgb(0, 255, 149); 
  color: white; 
}
select::selection {
  background-color: rgb(0, 255, 149);
  color: white; 
} */


/* Dla tekstu wewnątrz pola input (poza wskaźnikiem) */
/* input::-moz-selection {
  background-color: rgb(0, 255, 149); 
  color: white; 
} */
.txt-c{
  color: var(--maintext-color);
}
.txt-c-secon{
  color: var(--secondarytext-color);
}


* {
  margin:0px;
  padding: 0px;
}
a {
  text-decoration: none !important;
  color: var(--maintext-color);
}
body{
  background: var(--bg-color);
  /* font-family: Google Sans Display,Arial,Helvetica,sans-serif; */
  font-family: 'LibreFranklin';
  color: var(--maintext-color);
  transition: all 0.4s ease;
}
.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard */
}

/* Layout */


/* navigation  -------------------------- */


.wrapper nav.nav-bar{
  border-radius: 0px 0px 10px 0px;
  width: var(--width-nav);
  padding: 0px;
  margin-top: 0px;
  height: 100%;
  overflow: hidden;
  backdrop-filter: blur(2px);
  box-shadow: 3px 0px 10px 1px rgba(0, 0, 0, 0.338);
  background: #5163620f;
  /* background: var(--bgprimary-color); */
  position: relative;
  border-right: 0px solid rgba(0, 0, 0, 0.39);
  transition: all 0.4s ease;
}
.wrapper::before{
  content: '';
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  left: -100%;
  top:-10%;
  width: 100%;
  height: 100vh;
  
  background: #0bc4d900;
  box-shadow: 0 -100px 400px 10px rgba(0, 255, 234, 0.616)
  , 0 100px 400px 10px rgba(0, 81, 255, 0.678)
  , 50px 400px 200px 10px rgba(255, 145, 0, 0.596)
  ,inset 0 0  10px rgb(255, 166, 0);
}
.wrapper div.main-content{
  width: 96%;
  padding-block: 1%;
  padding-inline: 2%;
  overflow-y: auto;
}

.img-nav-open{
  fill: var(--secondary-fill-color);
  margin-left: 4px;
  margin-top: 10px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.img-def{
  transition: all 0.3s ease;
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  left: 15px;
  fill: var(--secondary-fill-color);
}
.img-nav{
  width: 22px;
  height: 22px;
  /* fill: rgb(141, 171, 253); */
  fill: var(--secondary-fill-color); 
}

.nav-pers-container{
  margin: 0.2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.nav-pers-container::after{
  content: "";
  height: 1px;
  width: 70%;
  margin-inline: auto;
  background: gray;
}
.nav-img-personal{
  display: none;
  width: 100%;
  max-width: 13vh;
  margin-inline: auto;
  border-radius: 50%;
  border: 1px solid var(--border-color);
}
.nav-txt-personal{
  height: 3.5vh;
  margin-top: 3px;
  margin-inline: auto;
  font-weight: 600;
  overflow: hidden;
  font-size: 20px;
  color: var(--maintext-color);
  width: var(--nav-text-width);
  transition: all 0.4s ease;
}


.nav-btn-item{
  width: available;
  display: flex;
  flex-direction: row;
  padding-left: 17px;
  padding-block: 0.6vh;
  transition: all 0.4s ease;
  margin-block: 1vh;
  
}
.nav-btn-item:hover{
  border-radius: 0px 0px 0px 0px;
  background: var(--bg-color);
 
}
.focused{
  border-radius: 10px 0px 0px 10px;
  background: var(--bg-color);
  transition: all 0.4s ease;

}
.nav-btn-item-text{
  font-weight: 500;
  margin-inline: 5px;
  font-size: var(--meintext-size);
  margin-block: auto;
  height: fit-content;
  text-decoration: none !important;
  text-underline-position: above;
  width: var(--nav-text-width);
  overflow: hidden;
  transition: all 0.4s ease;
} 
.hide-nav-btn{
  background: #7d7e7e4d;
  visibility: visible;
}
.hide-nav-btn::after{  
  content: '';
position: absolute;
   margin-left: 10px;
margin-top: 25px;

  width: 50px;
  height: 50px;
  color: rgba(127, 134, 134, 0.507);
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 -960 960 960" fill="rgba(127, 134, 134, 0.507)" xmlns="http://www.w3.org/2000/svg"><path d="M160-240H80v-480h80v480Zm320 0L240-480l240-240 56 56-143 144h487v80H393l144 144-57 56Z"/></svg>');
  background-repeat: no-repeat;
  background-position: center;

}


/* header  -------------------------- */


.header-block{
  
  margin-block:10px ;
  padding-block: 5px;
  margin-inline: 2.2%;
  padding-inline: 1%;
  border-left: 2px solid rgba(94, 143, 199, 0.925);
  /* background: rgba(128, 128, 128, 0);
  box-shadow: 2px 3px 5px 2px rgba(3, 3, 3, 0.137); */
  border-bottom: 0px solid var(--lines-color);
  border-radius: 4px;

  box-shadow: 3px 4px 24px 0px  rgba(0, 0, 0, 0.385);
  background: linear-gradient(90deg, rgba(133, 199, 250, 0.153) 0%,  rgba(244, 169, 103, 0.071) 100%);
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
}
.inner-header-block{
  border-radius: 5px;
  
 

  margin-block:10px ;
  padding-block: 5px;
  margin-inline: 0%;
  padding-inline: 1%;
  border-inline: 2px solid rgba(94, 143, 199, 0.925);
  background:linear-gradient(90deg, rgba(133, 199, 250, 0.153) 0%,  rgba(244, 169, 103, 0.071) 100%);
  border-bottom: 0px solid var(--lines-color);
  box-shadow: 2px 3px 5px 2px rgba(3, 3, 3, 0.137);
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
}
.folded-header-block{
  min-height: 3vh;
  margin-block:1px ;
  padding-block: 0px;
  margin-inline: 0%;
  padding-inline: 0%;
  border-bottom: 0px solid rgba(94, 199, 171, 0.925);
  box-shadow: 2px 3px 5px 2px rgba(3, 3, 3, 0.137);
  transition: all 0.3s ease;
  backdrop-filter: blur(10px);
  overflow: visible;
}
.folded-header-btn{
  width: 100%;
 margin-bottom: -2px;
 background: #1e2322;
  border-radius: 5px 5px 0px 0px;
  border-top: 1px solid #3e484e;
  border-right: 1px solid #3e484e;
  border-left: 1px solid #3e484e ;
  cursor: pointer;
}
.folded-header-btn:hover{
  background: #7b807e76 !important; 
}
.folded-header-btn p{
  text-align: center;
  margin-top: 10px;
}
.header-panels{
  width: -webkit-fill-available;
}
.folded-header-panel{
  width: -webkit-fill-available;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin-block: auto;
}
.header-panel{
  
  display: flex;
  flex-direction: row;
  height: 100%;
  
}
.header-txt{
  min-width: fit-content;
  font-size: 21px;
  margin-block: auto;
}

/* wraper  -------------------------- */


.wrapper{
  background: var(--bg-color);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  transition: all 0.4s ease;
}
.wrap-container{
  width: -webkit-fill-available;
  overflow-y: hidden;
}
.main-content{
  width: 100%;
  
}
.main-block{
  height: 90vh;
  overflow-y: auto;
}
main::-webkit-scrollbar {
  width: 6px; /* Szerokość paska przewijania */
}

main::-webkit-scrollbar-thumb {
  cursor: all-scroll;
  border-radius: 4px;
  background-color: rgba(46, 204, 113, 0.18); /* Kolor "główki" paska przewijania */
}

main::-webkit-scrollbar-track {
  background-color: transparent; /* Kolor tła obszaru paska przewijania */
}

main::-webkit-scrollbar-thumb:hover {
  background-color: #27ae60; /* Kolor "główki" paska przewijania po najechaniu kursorem */
}
.scrooll::-webkit-scrollbar {
  width: 6px; /* Szerokość paska przewijania */
}

.scrooll::-webkit-scrollbar-thumb {
  cursor: all-scroll;
  border-radius: 4px;
  background-color: rgba(46, 204, 113, 0.18); /* Kolor "główki" paska przewijania */
}

.scrooll::-webkit-scrollbar-track {
  background-color: transparent; /* Kolor tła obszaru paska przewijania */
}

.scrooll::-webkit-scrollbar-thumb:hover {
  background-color: #27ae60; /* Kolor "główki" paska przewijania po najechaniu kursorem */
}




/* loggin form --------------------------------------*/

.login-form-container{
   background: linear-gradient(333deg, rgba(255, 255, 255, 0) 1%,rgba(97, 97, 97, 0) 30%,rgb(27, 29, 31) 47%, rgb(29, 31, 31) 100%); 
  height: 100vh;
  width: 100%;
  backdrop-filter: blur(2px);
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
.login-form-container::before{
  content: '';
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  left: -100%;
  top:-50%;
  width: 100%;
  height: 100vh;
  
  background: #0bc4d900;
  box-shadow: 0 -100px 400px 10px rgba(0, 255, 234, 0.616)
  , 0 100px 400px 10px rgba(0, 81, 255, 0.678)
  ,inset 0 0  10px rgb(255, 166, 0);
}
.login-form-container::after{
  content: '';
  z-index: 0;
  position: absolute;
  border-radius: 50%;
  left: -100%;
  bottom:-30%;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #0bc4d900;
  box-shadow: 
  -80px 100px 400px 10px rgba(255, 158, 47, 0.459)
  ,inset 0 0  10px rgb(255, 166, 0);
}

.login-form-block{
  margin-inline:auto;
}
.login-form-header{
 
  width: fit-content;
  margin-inline:auto;
}
.login-form-header-txt{
  margin-top: 3vh;
  margin-bottom: 20px;
  border-left: 1px solid gray;
  padding-left: 10px;
  animation:  apear-left-to-right 2s ease forwards;
}
.login-form-body{
  width: fit-content;
  margin-inline:auto;
  margin-top: 5vh;
}
.btn-login{
  font-size: 23px;
  border-radius: 4px;
  float: right;
  padding-block: 5px;
  padding-inline: 13px;
  color: var(--maintext-color);
  border: 1px solid rgba(248, 186, 71, 0.185);
  background: transparent;
  cursor: pointer;
  margin-top: 10px;
  transition: all 0.3s ease;
}
.btn-login:hover{
  border-radius: 20px;
  border: 1px solid rgb(73, 187, 240);
}

.errmsg{
  color: red;
}
.logo-container{
  width: fit-content;
  margin-inline: auto;
 
}
.inpt-login{
  margin-block: 20px;
}
.inpt-login input{
  border-radius: 0px 7px 7px 0px;
  padding: 5px;
  -webkit-text-fill-color: #b4b4b4;
  font-size: 22px;
  background: #3160e200;
  border: 0px solid transparent;
  box-shadow: 15px 0px 10px 1px #ffc72c4f;
  border-left: 1px solid gray;
  color: #f3f3f3;
  transition: all 0.3s ease;
}
/* input:focus {
  outline: none;
  box-shadow: none;
  box-shadow: 15px 0px 10px 1px #28cce2e8;
} */

.svglogo{
  z-index: 5;
  animation: logo-anim 9s linear infinite;
}

.infopanel{
  border-radius: 3px;
  background: #00000023;
  padding: 10px;
  margin-bottom: 10px;
  border-right: 1px solid rgb(255, 115, 0);
  border-left: 1px solid rgb(255, 115, 0);
  box-shadow: inset 0 0 5px rgba(155, 132, 72, 0.505);
  white-space: pre-wrap;
}

@keyframes logo-anim {
  0%{
    rotate: 0deg;
  }
  50%{
    transform:  scale(0.98);
  }
  100%{
    rotate: 0deg;
  }
}
#neonGradient stop:first-child {
  animation: logo-anim-color-one 9s linear infinite;
  stop-color: rgba(100, 98, 98, 0.32);
}

#neonGradient stop:last-child {

  animation: logo-anim-color-two 9s linear infinite;
  stop-color: #0bc6d9;
}
#waiting-icon stop:first-child {
  animation: waiting-one 3s linear infinite;
  stop-color: rgba(100, 98, 98, 0.32);
}

#waiting-icon stop:last-child {

  animation: waiting-two 3s linear infinite;
  stop-color: #0bc6d9;
}
@keyframes logo-anim-color-one {
  0%{
    stop-color: rgba(100, 98, 98, 0.32);
  }
  50%{
    stop-color: rgba(55, 66, 66, 0.07);
  }
  100%{
    stop-color: rgba(100, 98, 98, 0.32);
  }
}
@keyframes logo-anim-color-two {
  0%{
   
    stop-color: #0bc6d9;
  }
  50%{
   
    stop-color: rgba(99, 56, 218, 0.96);
  }
  100%{
   
    stop-color: #0bc6d9;
  }
}
@keyframes waiting-one {
  0%{
    transform: rotate(0);
    stop-color: rgba(100, 98, 98, 0.32);
  }
  50%{
    transform: scale(0.7);
    stop-color: rgba(55, 66, 66, 0.07);
  }
  100%{
    transform: rotate(360deg);
    stop-color: rgba(100, 98, 98, 0.32);
  }
}
@keyframes waiting-two {
  0%{
    transform: rotate(0);
    stop-color: #0bc6d9;
  }
  50%{
    transform: scale(0.7);
    stop-color: rgba(99, 56, 218, 0.96);
  }
  100%{
    transform: rotate(360deg);
    stop-color: #0bc6d9;
  }
}
@keyframes apear-left-to-right {
  0%{
    transform: translateX(-200px);
  }
  60%{
    transform: translateX(20px);
  }
  100%{
    transform: translateX(0px);
  }
}

/* addictionals  */
.icon{
  margin: 2px;
  width: 26px;
  height: 26px;
  
}
.icon-blue{
  fill: rgb(61, 61, 246);
}
.icon-red{
  fill: rgb(246, 89, 61);
}
.icon-green{
  fill: rgb(61, 246, 147);
}
.icon-yellow{
  fill: rgb(246, 231, 61);
}
@media screen and (max-height:500px){
  .nav-txt-personal{
    display: none;
  }

}
@media  screen and (max-width:900px){
  .hide-nav-btn{
    visibility: visible;
  }
}