.modal-update{
   position: fixed;
   top: 0px;
   left: 0;
   bottom: 0;
   right: 0;
   background: rgba(128, 128, 128, 0.151);
   transition: all 0.4s ease;
}
.modal-menu{
   position: fixed;
   top: 0px;
   left: 0;
   bottom: 0;
   right: 0;
   backdrop-filter: blur(3px);
   transition: all 0.4s ease;
}
.modal-window{
   position: absolute;
   top: 0;
   right: 0;
   left: 0;
   bottom:0;
   margin-inline: auto;
   margin-block: auto;
   box-shadow: 0px 0px 20px gray;
   border-left: 4px solid rgba(94, 143, 199, 0.925);
   border-right: 4px solid rgba(94, 143, 199, 0.925);
   width: 90%;
   height: fit-content;
   max-height: 90%;
   overflow-y: scroll;
   padding-block: 2% ;
   padding-inline: 1%;
   background:var(--bg-modal-color) ;
   backdrop-filter: blur(17px) ;
}
.modal-w-title{
   width: fit-content;
   margin-inline: auto;
}
.modal-client-header-container{
   position: relative;
   margin-bottom: 20px;
   background: linear-gradient(90deg, rgba(108, 155, 220, 0.471) 0%,  rgba(57, 201, 127, 0.526) 100%);
   backdrop-filter: blur(4px);
   border-radius: 4px;
   margin-top: 14px;
   box-shadow: 3px 1px 10px #000000ad;
   border-left: 4px solid #64aa69;
   border-right: 4px solid #64aa69;
   border-bottom: 1px solid rgba(128, 128, 128, 0.393);
   border-top: 1px solid rgba(128, 128, 128, 0.393);
}
.modal-client-header-container::after{
   content: attr(data-label);
   padding-block: 10px;
   position: absolute;
   bottom: -30px;
   left: 0;
   right: 0;
   overflow: hidden;
   text-align: center;
   text-overflow: clip;
   width: 53px;
   margin-inline: auto;
   font-size: 25px;
   border-radius: 50%;
   border: 1px solid gray;
   background: var(--bg-color);
}
.price-modal-client{
   position: absolute;
   top: 10px;
   right: 10px;
   font-size: 16px;
   color: rgb(153, 153, 153);
   text-shadow: 2px 0px 10px rgb(45, 116, 48);
}
.modal-client-header-container-txt{
   width: fit-content;
   font-size: 20px;
   margin-top: 25px;
   margin-bottom: 0px;
   margin-inline: auto;
}

.m-c-h-c-container{
   margin-inline: auto;
   width: fit-content;
   margin-bottom: 0px;
   
}
.line-moadal-stage-container{
   width: 100%;
   margin-top: 2px;
   display: flex;
   flex-direction: row;
}
.line-moadal-stage{
   
   height: 4px;
   width: 100%;
}
.line-modal-stage-chosen{
   font-size: 18px;
   padding-block: 5px;
   padding-inline: 5px;
   text-align: left;
   margin-bottom: 4px;
   border-radius: 3px 3px 0px 0px ;
}
.container-line{
   margin-inline: auto;
   width: 70%;
   margin-bottom: 25px;
}
.bg-color-modal{
   background: var(--bg-modal-color2) ;
   box-shadow: 0 0 90px 20px #00000096;
}

@media screen and (max-width:900px) {
   .modal-window{
      width: 94%;
   }
}