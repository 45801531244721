.form-container{
   display: flex;
   flex-wrap: wrap;
   justify-content: space-evenly;
   transition: all 0.7s ease;
   border-top: 1px solid var(--lines-color);
   padding-top: 5px;
   margin-top: 5px;
}
.from-input{
   width: 99%;
   font-size: 14px;
   background: rgba(81, 101, 131, 0);
   border:0px solid rgba(128, 128, 128, 0);
   padding-block: 3px;
   padding-inline: 1%;
   border-radius: 0px;
}
.from-input-wraper::before{
   content: attr(data-label)": ";
   position: absolute;
   transition: all 0.3s ease;
   top: -8px;
   left: 1%;
   font-size: 11px;
   color: rgba(133, 133, 133, 0.824);
}
.from-input-wraper:hover::before{
   content: attr(data-label)": ";
   position: absolute;
   top: -8px;
   left: -5px;
   color: rgba(94, 143, 199, 0.92);
}
.from-input-wraper{
   box-shadow: 0 0 20px #00000026;
   width: 23%;
   margin-block: 5px;
   padding-block: 3px;
   margin-inline: auto;
   background: var(--bg-inputs-color);
   border:0px solid rgba(128, 128, 128, 0.281);
   border-bottom: 1px solid rgba(147, 171, 196, 0.195);
   border-top: 1px solid rgba(105, 117, 129, 0.242);
   backdrop-filter: blur(1px);
   transition: all 0.3s ease;
}
.from-input-wraper:hover{
   width: 23%;
   margin-inline: auto;
   background: rgba(81, 101, 131, 0.048);
   border:0px solid rgba(128, 128, 128, 0.479);
   border-bottom: 1px solid rgba(94, 143, 199, 0.925);
   border-top: 1px solid rgba(147, 171, 196, 0.096);
}


.flex-from-input-wraper::before{
   content: attr(data-label)": ";
   position: absolute;
   transition: all 0.3s ease;
   top: -8px;
   left: 1%;
   font-size: 11px;
   color: rgba(133, 133, 133, 0.824);
}
.flex-from-input-wraper:hover::before{
   content: attr(data-label)": ";
   position: absolute;
   top: -8px;
   left: -5px;
   color: rgba(94, 143, 199, 0.92);
}
.flex-from-input-wraper{
   box-shadow: 0 0 20px #00000026;
   width: 99%;
   margin-block: 5px;
   padding-block: 3px;
   margin-inline: auto;
   background: var(--bg-inputs-color);
   border:0px solid rgba(128, 128, 128, 0.281);
   border-bottom: 1px solid rgba(147, 171, 196, 0.427);
   border-top: 1px solid rgba(105, 117, 129, 0.388);
   backdrop-filter: blur(1px);
   transition: all 0.3s ease;
}
.flex-from-input-wraper:hover{
   width: 99%;
   margin-inline: auto;
   background: rgba(81, 101, 131, 0.048);
   border:0px solid rgba(128, 128, 128, 0.479);
   border-bottom: 1px solid rgba(94, 143, 199, 0.925);
   border-top: 1px solid rgba(147, 171, 196, 0.096);
}

input[type="date"]::-webkit-calendar-picker-wrapper {
   /* Stylizacja wrappera kalendarza */
   padding: 0px;
   
}

input[type="date"]::-webkit-calendar-picker-indicator {
   /* Stylizacja ikony kalendarza */
   padding: 0px;

}

input[type="date"]::-webkit-inner-spin-button {
   /* Stylizacja strzałki wewnętrznej */
   
}

.form-button{
   border-radius: 3px;
   font-size: 15px;
   border: 1px solid rgb(76, 191, 219);
   padding-block: 5px;
   padding-inline: 40px;
   margin-block: 10px;
   width: fit-content;
   margin-inline: 30%;
   background: rgba(0, 0, 0, 0);
   cursor: pointer;
   transition: all 0.4s ease;
}
.form-button:hover{
   box-shadow: 0 0 10px 2px  #00ffff41, 0 0 0 1px  #366b011c;
   background: #4cc7df5a;
}
.header-name{
   font-weight: 200;
   text-align: center;
   font-size: 18px;
   width: 96%;
   filter: contrast(30%);
   margin-block: 5px;
   border-bottom:1px solid rgba(82, 82, 69, 0.068);
}

/* form selection */
.form-selection{
   width: 99%;

   height: fit-content;
   font-size: 14px;
   background: rgba(81, 101, 131, 0);
   border:0px solid rgba(128, 128, 128, 0);
   padding-block: 3px;
   padding-inline: 1%;
}
.from-option{
   background: var(--bg-color);
   margin: 0px;
   padding: 0px;
}
.form-feedback-icon{
   width: fit-content;
   height: fit-content;
   margin-inline: auto;
   margin-block: auto;
   display: flex;
   flex-direction: column;
}
.form-feedback-button{
   width: fit-content;
   height: fit-content;
   padding-inline: 20px;
   padding-block: 4px;
   margin-inline: auto;
   margin-block: 10px;
   border-radius: 3px;
   font-size: 15px;
   border: 1px solid rgb(115, 219, 245);
   background: rgba(0, 0, 0, 0);
   cursor: pointer;
   transition: all 0.4s ease;
}
.form-feedback-button:hover{
   box-shadow: 0 0 10px 2px  #00ffff41, 0 0 0 1px  #366b011c;
}
.img-form{
   width: 80px;
   height: 80px;
   margin-inline: auto;
}





/* client form */
.clientform-container{
   display: flex;
   flex-wrap: wrap;
   justify-content: space-evenly;
   transition: all 0.7s ease;
   border-top: 0px solid var(--lines-color);
   padding-top: 5px;
   margin-top: 5px;
}
.clientform-input{
   width: 99%;
   font-size: 14px;
   background: rgba(81, 101, 131, 0);
   border:0px solid rgba(128, 128, 128, 0);
   padding-block: 3px;
   padding-inline: 2%;
   border-radius: 0px;
  
}
.clientform-input-wraper::before{
   content: attr(data-label)": ";
   padding-left: 1%;
   transition: all 0.3s ease;
   font-size: 11px;
   color: rgba(133, 133, 133, 0.824);
}
.clientform-input-wraper:hover::before{
   content: attr(data-label)": ";
   
   color: rgba(94, 143, 199, 0.92);
}
.clientform-input-wraper{
  
   width: 23%;
   margin-block: 5px;
   padding-block: 3px;
   margin-inline: auto;
   border:0px solid rgba(128, 128, 128, 0.281);
   border-bottom: 1px solid rgba(147, 171, 196, 0.096);
   border-top: 0px solid rgba(105, 117, 129, 0.164);
   transition: all 0.3s ease;
}
.clientform-input-wraper:hover{
   width: 23%;
   margin-inline: auto;
   background: rgba(81, 101, 131, 0.048);
   border:0px solid rgba(128, 128, 128, 0.479);
   border-bottom: 1px solid rgba(94, 143, 199, 0.925);
   border-top: 0px solid rgba(147, 171, 196, 0.096);
}
.clientform-selection{
   width: 99%;

   height: fit-content;
   font-size: 14px;
   background: rgba(81, 101, 131, 0);
   border:0px solid rgba(128, 128, 128, 0);
   padding-block: 3px;
   padding-inline: 0%;
}






.wide-container{
   display: flex;
   flex-wrap: wrap;
   max-width: 49.94%;
   justify-content: space-evenly;
   transition: all 0.7s ease;
   border-top: 0px solid var(--lines-color);
   padding-top: 5px;
   margin-top: 5px;
   border-right: 1px solid gray;
}

.wide-input{
   width: 99%;
   font-size: 14px;
   background: rgba(81, 101, 131, 0);
   border:0px solid rgba(128, 128, 128, 0);
   padding-block: 3px;
   padding-inline: 2%;
   border-radius: 0px;
  
}
.wide-input-wraper::before{
   content: attr(data-label)": ";
   padding-left: 1%;
   transition: all 0.3s ease;
   font-size: 13px;
   margin-block: auto;
   width: 53%;
   color: rgb(113, 113, 113);
}
.wide-input-wraper:hover::before{
   content: attr(data-label)": ";
   
   color: rgba(94, 143, 199, 0.92);
}
.wide-input-wraper{
  
   margin-block: 5px;
   margin-inline: auto;
   border:0px solid rgba(128, 128, 128, 0.281);
   border-bottom: 1px solid rgba(147, 171, 196, 0.096);
   border-top: 0px solid rgba(105, 117, 129, 0.164);
   transition: all 0.3s ease;

   width: 95%;
   min-width: 95%;
   max-width: 95%;
   margin-block: 1px;
    padding-block: 2px;
    display: flex;
    flex-direction: row;
}
.wide-input-wraper:hover{
   width: 23%;
   margin-inline: auto;
   background: rgba(81, 101, 131, 0.048);
   border:0px solid rgba(128, 128, 128, 0.479);
   border-bottom: 1px solid rgba(94, 143, 199, 0.925);
   border-top: 0px solid rgba(147, 171, 196, 0.096);
}
.wide-selection{
   width: 99%;

   height: fit-content;
   font-size: 14px;
   background: rgba(81, 101, 131, 0);
   border:0px solid rgba(128, 128, 128, 0);
   padding-block: 3px;
   padding-inline: 0%;
}





@media screen and (max-width:800px) {
   .from-input-wraper{
      width: 46%;
      min-width: 46%;
      max-width: 46%;
      
   }
   .from-input-wraper{
      width: 46%;
     min-width: 46%;
     max-width: 46%;
     
   }
   .from-input{
      font-size: 18px;
   }
   .form-selection{
      font-size: 18px;
   }
   .clientform-input-wraper{
      width: 46%;
      min-width: 46%;
      max-width: 46%;
      
   }
   .clientform-input-wraper{
      width: 95%;
     min-width: 95%;
     max-width: 95%;
     margin-block: 1px;
      padding-block: 2px;
      display: flex;
      flex-direction: row;
   }
   .clientform-input-wraper::before{
      content: attr(data-label)": ";
      margin-block: auto;
      transition: all 0.3s ease;
      width: 53%;
      font-size: 14px;
      color: rgba(133, 133, 133, 0.824);
   }
   .clientform-input{
      font-size: 19px;
      padding-inline: 2%;
   }
   .clientform-selection{
      font-size: 19px;
      padding-inline: 0%;
   }
   
   .wide-container{
      max-width: 100%;
   }
   .wide-input{
      width: 99%;
      font-size: 17px;
   }
}